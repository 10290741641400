<script>
import {defineComponent} from "vue";
export default defineComponent({
  name: 'MobileMenu',
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
      if(this.isOpen){
        this.menuBurger = 'bi bi-x-lg';
      }else {
        this.menuBurger = 'bi bi-list';
      }
    },
    handleClick() {
      this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
    }
  },
  data(){
    return{
      isOpen: false,
      menuBurger: 'bi bi-list',
      ref: null
    }
  }
});
</script>

<template>
  <div class="container">
    <div class="d-md-none">
    <div class="row mt-3 d-flex justify-content-between">
      <div class="col-5">
        <a href="/"><img src="@/assets/logo.png" alt="" class="img-fluid"></a>
      </div>
      <div class="col-2" @click="toggleMenu">
        <i :class="this.menuBurger"></i>
      </div>
    </div>
    <div class="row mt-3" v-if="isOpen">
      <div class="col-12 mb-2 header-menu" @click="$router.push('/order-1')">
        Оформить заявку
      </div>
      <div class="col-12 mt-3 header-menu">
        <a href="#service">О&nbsp;сервисе</a>
      </div>
    </div>
  </div>

  <div class="d-none d-md-flex">
    <div class="d-flex align-items-center pt-3">
      <div class="logo col-auto me-5">
        <a href="/"><img src="@/assets/logo.png" alt="" class="img-fluid"></a>
      </div>
      <div class="col-5 header-menu"  @click="$router.push('/order-1')">
        <a href="#">Оформить заявку</a>
      </div>
      <div class="col-auto mt-3 header-menu">
        <a href="#service">О&nbsp;сервисе</a>
      </div>
    </div>
  </div>
  </div>

</template>

<style scoped lang="scss">
i{
  font-size: 30px;
}
.header-menu {
  cursor: pointer;
  padding-top: 0;
  margin-top: 0!important;
  a {
    color: #1E1E1E;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }
}
</style>