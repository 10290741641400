<script>
import {SwiperSlide} from "swiper/vue";

export default {
	name: 'BtnIcon',
	props: {
		card: {
			require: true,

		}
	},
}
</script>

<template>
	<div class="card-block">
		<div class="card-block__item">
			<a href="#leadForm">
				<div class="card-block__body">
					<div class="card-block__img text-center" style="height: 30px;" v-html="card.icon"></div>
					<div class="card-block__text text-center" v-html="card.text"></div>
				</div>
			</a>
		</div>
		
	</div>
</template>

<style scoped lang="scss">
.card-block {
	&__item {
		background-color: #fff;
		padding: 20px 15px;
		@media(max-width: 1199px) {
			padding: 10px 5px!important;
		}
	}
	&:hover .card-block__item{
		cursor: pointer;
		background-color: #1866EB;
		box-shadow: 0px 4px 12px 0px rgba(35, 69, 129, 0.05);
		color: #fff;
	}
	&:hover a {
		color: #fff;
	}
	&__item {
		background-color: #fff;
		padding: 20px 15px;
		border-radius: 16px;
	}
	a {
		display: block;
		color: #818181;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		display: block;
		text-decoration: none;
		@media(max-width: 575px) {
			font-size: 10px;
		}
	}
}



</style>



