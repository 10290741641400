<script>
import CardColorBg from "@/components/CardColorBg.vue";
import DarkBlueCardItem from "@/components/DarkBlueCardItem.vue";
import BtnYellow from "@/components/BtnYellow.vue";
import YellowAncorLink from "@/components/YellowAncorLink.vue";

export default {
	name: 'BlockFour',
	components: {YellowAncorLink, BtnYellow, DarkBlueCardItem, CardColorBg},
	data() {
		return {
			card: [
				{
					img: 'rules1.svg',
					title: 'Гражданство РФ',
					text: 'Регистрация на территории Российской Федерации'
				},
				{
					img: 'rules2.svg',
					title: 'Возраст от 18 до 65 лет',
					text: 'Для оформления нужен только паспорт'
				},
				{
					img: 'rules3.svg',
					title: 'Постоянный доход',
					text: 'Необязательно белый'
				},
			]
		}
	}
}
</script>

<template>
	<div class="row mt-5 gradient" id="blockFour">
		<div class="col-12">
			<h2 class="title">Условия получения займа</h2>
		</div>
	</div>
	<div class="row mt-5">
		<div class="col-12 col-lg-4 rules-item" v-for="(item, i) in card" :key="i">
			<div class="rules-item__img">
				<img :src="require(`@/assets/img/${item.img}`)" alt="svg" loading="lazy">
			</div>
			<div class="rules-item__title">{{ item.title }}</div>
			<div class="rules-item__text">{{ item.text }}</div>
		</div>
	</div>
	<div class="col-12 col-lg-4 btn-order mx-auto mt-5 text-center">
		<yellow-ancor-link :ancor="'#leadForm'">Оформить заявку</yellow-ancor-link>
	</div>
</template>

<style scoped lang="scss">
.rules-item {
	text-align: center;
	margin-bottom: 0;
	@media(max-width: 990px) {
		margin-bottom: 30px;
	}
	&:last-child {
		margin-bottom: 0;
	}
	&__img {
		height: 190px;
	}
	&__title {
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 20px;
		@media(max-width: 990px) {
			font-size: 20px;
			margin-bottom: 10px;
		}
	}
	&__text {
		color: #818181;
		font-size: 14px;
		font-weight: 400;
		line-height: normal;
		@media(max-width: 990px) {
			max-width: 80%;
			margin: 0 auto;
		}
	}
}
</style>