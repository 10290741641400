<script>
import {defineComponent} from "vue";
import BtnYellow from "@/components/BtnYellow.vue";
import RangeCard from "@/components/RangeCard.vue";

export default defineComponent({
  name: 'BlueCard',
  components: {RangeCard, BtnYellow},
});
</script>

<template>
  <div>
    <div class="card blue-card position-relative">
      <div class="card-body p-3 p-md-4">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.blue-card {
  border-radius: 24px;
  background: url("@/assets/img/stick.png"), linear-gradient(90deg, #10192C 0.26%, #24374C 99.45%);
  color: #fff;
  background-repeat: no-repeat;
  background-position: top right;
}



</style>