<script>
import CardGrey from "@/components/CardGrey.vue";
import CardColumn from "@/components/CardColumn.vue";
import YellowAncorLink from "@/components/YellowAncorLink.vue";

export default {
	name: 'BlockSeven',
	components: {CardColumn, CardGrey,YellowAncorLink},
	data(){
		return{
			cards:[
				{
					title: 'Не перезваниваем и не требуем фото с паспортом',
					text: 'Достаточно анкеты'
				},
				{
					title: 'Не проверяем вашу кредитную историю',
					text: 'Работаем на доверии'
				},
				{
					title: 'Не беспокоим ваших родных и коллег',
					text: 'Мы доверяем вам и не просим справки с работы, не звоним родственникам и коллегам'
				},
				{
					title: 'Ваши данные защищены',
					text: 'Ваши персональные данные и данные вашей банковской карты никогда не перейдут третьим лицам'
				},
			]
		}
	}
}
</script>

<template>
	<div class="map-block">
		<div class="row" id="blockSeven">
			<div class="col-12">
				<h2 class="title">5 миллионов человек выбрали нас.<span>Доверьтесь и Вы!</span></h2>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-lg-7 map-block__map">
				<img src="@/assets/img/map.png" alt="" class="img-fluid">
			</div>
			<div class="col-12 col-lg-5 list">
				<div class="list__item" v-for="(item, i) in cards" :key="i">
					<div class="list__title">{{ item.title }}</div>
					<div class="list__text">{{ item.text }}</div>
				</div>
				<yellow-ancor-link class="list__btn" :ancor="'#leadForm'">Оформить заявку</yellow-ancor-link>
			</div>
		</div>
	</div>

</template>

<style scoped lang="scss">
.map-block {
	border-radius: 30px;
	background: #FFF;
	box-shadow: 0px 4px 12px 0px rgba(35, 69, 129, 0.05);
	padding: 50px 20px 70px;
	@media(max-width: 991px) {
		padding: 30px 15px;
	}
	&__map {
		display: block;
		margin: 0 auto;
		@media(max-width: 991px) {
			margin-bottom: 20px;
			margin-top: -20px;
		}
		img {
			display: block;
			@media(max-width: 991px) {
				//max-width: 80%;
				margin: 20px auto 0;
			}
		}
	} 
	h2 {
		span {
			color: #005BF7;
			display: block;
		}
	}
}
.list {
	&__item {
		margin-bottom: 20px;
		position: relative;
		padding-left: 32px;
		&::before {
			content: '';
			display: block;
			background-image: url('@/assets/img/checked.svg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 0;
			top: 5px;
		}
	}
	&__title {
		font-size: 24px;
		font-weight: 500;
		line-height: normal;
		margin-bottom: 10px;
	}
	&__text {
		color: #818181;
		font-size: 16px;
		font-weight: 400;
		line-height: normal;
	}
	&__btn {
		display: inline-block;
		width: 320px;
		text-align: center;
		margin-left: 32px;
		@media(max-width: 990px) {
			width: 100%;
			margin-left: 0;
		}
	}
}
</style>