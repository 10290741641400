<script>
import BlueCard from "@/components/BlueCard.vue";
import BtnYellow from "@/components/BtnYellow.vue";

export default {
  name: 'ResultBlock',
  components: {BtnYellow, BlueCard},
  methods:{
    vitrinaLink(){
      window.location.href ='/vitrina1/?showcase_id=48&user_id=1&offer_id=1'
    }
  },
  data() {
    return {
      lead: this.lead,
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<template>
  <div>
    <div class="row pb-5 justify-content-between">
      <div class="col-3">
        <a class="logo" href="/"><img src="@/assets/logo.png" alt=""></a>
      </div>
      <div class="col-3 col-md-1 text-end d-flex align-items-end">
        <a href="#service">О сервисе</a>
      </div>
    </div>
    <blue-card>
      <div class="row">
        <div class="col-12 ">
          <h1 class="text-center"><span style="text-transform: capitalize;"> {{ this.lead.person.f }} {{ this.lead.person.i }} {{ this.lead.person.o }}</span>,
            поздравляем Вас,<br>
            ваша заявка <span class="yellow-text">одобрена</span>!</h1>
        </div>
      </div>
      <div class="row card-text pt-3 ps-4 pe-4 pb-0 align-items-center justify-content-center">
        <div class="col-4 col-md-2 text-center">
          <p class="mb-1">Сумма займа</p>
          <p class="mb-1 text-bold sum">{{ this.lead.sum }} ₽</p>
        </div>
        <div class="col-4 col-md-2 text-center">
          <p class="mb-1 text-center">Первый займ</p>
          <p class="mb-1 yellow-text text-bold text-center">0%</p>
        </div>
      </div>
      <div class="row mt-5 justify-content-center">
        <div class="col-12 col-md-3">
          <btn-yellow><a href="/vitrina1/?showcase_id=48&user_id=1&offer_id=1" target="_blank">Выбрать предложение</a></btn-yellow>
        </div>
      </div>
    </blue-card>
  </div>
</template>

<style scoped>
a{
  color: #000;
  text-decoration: none;
}

a:hover{
  color: #fff;
  text-decoration: none;
}
</style>
