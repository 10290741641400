<template>
	<div class="row line" id="blockTwo">
		<div class="col-12 gradient  pt-5 mb-3 mb-lg-4 text-center">
			<h2 class="title">Как работает сервис?</h2>
		</div>
	</div>
	<div class="card-block">
		<ol class="card__list row" >
			<li class="card__item col-12 col-lg-3 px-2" v-for="(item, i) in card" :key="i">
				<div class="card__title" v-html="item.title"></div>
				<div class="card__text" v-html="item.text"></div>
			</li>
		</ol>
	</div>
	<!-- <div class="row mt-3 ps-md-5">
		<dark-blue-card-item class="mt-3" v-for="(card, i ) in cards" :key="i" :card="card"></dark-blue-card-item>
	</div> -->
	<div class="row justify-content-center mt-3 mt-md-5">
		<div class="col-12 col-lg-4 btn-order text-center">
			<yellow-ancor-link :ancor="'#leadForm'">Оформить заявку</yellow-ancor-link>
		</div>
	</div>

</template>

<script>
import DarkBlueCardItem from "@/components/DarkBlueCardItem.vue";
import LeftMenuItem from "@/components/LeftMenuItem.vue";
import BtnYellow from "@/components/BtnYellow.vue";
import leadForm from "@/components/LeadForm.vue";
import YellowAncorLink from "@/components/YellowAncorLink.vue";

export default {
	name: 'BlockTwo',
	computed: {
		leadForm() {
			return leadForm
		}
	},
	components: {YellowAncorLink, BtnYellow, LeftMenuItem, DarkBlueCardItem},
	methods:{

	},
	data() {
		return {
			card: [
				{
					title: 'Выберите сумму и срок займа',
					text: 'Оформите заявку на займ вне зависимости от вашего местоположения, главное иметь интернет, паспорт и банковскую карту'
				},
				{
					title: 'Заполните данные для получения денег',
					text: 'Расскажите немного о&nbsp;себе —&nbsp;откуда Вы, какой займ Вам нужен'
				},
				{
					title: 'Дождитесь одобрения сервиса',
					text: 'Решение по вашей заявке будет принято в течение нескольких секунд –&nbsp;<span>не закрывайте сайт!</span>'
				},
				{
					title: 'Получите деньги',
					text: 'Перечисление на карту или любым удобным способом'
				}
			]
		}
	}

}
</script>



<style scoped lang="scss">
.gradient {
	/* background: linear-gradient(#010101, #111212); */
	background-color: #F2FBFF;
}

.line {
	/* border-top: 1px solid #212121; */
}
.card {
	&__list {
		list-style-type: none; 
		counter-reset: num;
		margin: 0 0 0 35px;
		padding: 15px 0 5px 0;
		font-size: 18px;
		@media (max-width: 991px) {
			margin: 0 0 20px;
		}
	}
	&__item {
		position: relative;	
		margin: 0;
		padding: 0;
		text-align: center;
		z-index: 2;
		@media (max-width: 991px) {
			text-align: left;
			margin-bottom: 30px;
			display: flex;
			flex-direction: column;
			padding-left: 80px!important;
			//margin-left: 70px;
		}
		&:before {
			content: counter(num) ''; 
			counter-increment: num;
			display: block; 

			width: 64px;
			height: 64px;
			border-radius: 16px;
			color: #fff;
			background-color: #005BF7;
			font-size: 32px;
			font-weight: 700;
			text-align: center;
			line-height: 64px;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			margin-bottom: 25px;
			z-index: 5;
			@media (max-width: 991px) {
				left: 0px;
				transform: translateY(0);
				flex: 0 0 64px;
				margin-bottom: 10px;
				position: absolute;

			}
		}
		&::after {
			content: '';
			display: block;
			height: 1px;
			width: 50%;
			background-color: #ADADAD;
			position: absolute;
			top: 32px;
			right: -23%;
			z-index: 1;
			@media (max-width: 991px) {
				right: auto;
				left: 32px;
				bottom: 0;
				top: 50%;
				z-index: 0;
				height: 100%;
				width: 1px;
			}
			@media (max-width: 575px) {
				top: 30%;
			}
		}
		&:last-child {
			&::after {
				display: none;
			}
		}
	}
	&__title {
		color: #1E1E1E;
		font-size: 24px;
		font-weight: 500;
		line-height: 120%;
		margin-bottom: 15px;
		min-height: 60px;
		@media (max-width: 991px) {
			min-height: auto;
		}
	}
	&__text {
		color: #818181;
		font-size: 14px;
		font-weight: 400;
		line-height: normal;
		span {
			color: #1E1E1E;
			font-size: 20px;
		}
	}
}
.btn-order {
	padding-bottom: 30px;
}
</style>