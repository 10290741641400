import { createRouter, createWebHistory } from 'vue-router'
import PageOrderOne from '@/pages/PageOrderOne.vue'
import PageOrderTwo from '@/pages/PageOrderTwo.vue'
import PageOrderThree from '@/pages/PageOrderThree.vue'
import Main from '@/pages/Main.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/order-1',
    component: PageOrderOne
  },
  {
    path: '/order-2',
    component: PageOrderTwo
  },
  {
    path: '/order-3',
    component: PageOrderThree
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
