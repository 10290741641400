<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import DarkBlueCardItem from "@/components/DarkBlueCardItem.vue";
import CardPay from "@/components/CardPay.vue";
import {Navigation} from "swiper/modules";
export default {
	name: 'BlockThree',
	components: {
		CardPay,
		DarkBlueCardItem,
		Swiper,
		SwiperSlide,
		Navigation,
	},

	methods:{
		changeRow(){
			let next = document.getElementsByClassName("swiper-button-next");
			let prev = document.getElementsByClassName("swiper-button-prev");
		}
	},
	setup() {
		return {
			Swiper,
			modules: [Navigation]
		};
	},
	data() {
		return {
			// navigation: {
			//   //Next
			//   nextEl: '.swiper-button-next',//The name of the next label can be customized
			//   //Previous
			//   prevEl: '.swiper-button-prev'//The name of the previous label can be customized
			// },
			// breakpoints: {
			//   // when window width is >= 320px
			//   150: {
			//     slidesPerView: 2.2,
			//     spaceBetween: 10
			//   },
			//   // when window width is >= 640px
			//   768: {
			//     slidesPerView: 3,
			//     spaceBetween: 20
			//   },
			//   1200: {
			//     slidesPerView: 5,
			//     spaceBetween: 30
			//   }
			// },
			// cards: [
			//   {
			//     id: 1, title: 'Карты платежной системы MasterCard',
			//     img: require('@/assets/img/mastercard.png'),
			//     col: 'col-6 col-md-2'
			//   },
			//   {
			//     id: 2, title: 'Карты платежной системы VISA',
			//     img: require('@/assets/img/visa.png'),
			//     col: 'col-6 col-md-2'
			//   },
			//   {
			//     id: 3, title: 'Карты платежной системы Maestro',
			//     img: require('@/assets/img/maestro.png'),
			//     col: 'col-6 col-md-2'
			//   },
			//   {
			//     id: 4, title: 'Карты платежной системы МИР',
			//     img: require('@/assets/img/mir.jpg'),
			//     col: 'col-6 col-md-2'
			//   },
			//   {
			//     id: 5, title: 'Система быстрых платежей СБП',
			//     img: require('@/assets/img/sbp.png'),
			//     col: 'col-6 col-md-2'
			//   },
			//   {
			//     id: 6, title: 'Электронный кошелек <br> Qiwi',
			//     img: require('@/assets/img/qiwi.png'),
			//     col: 'col-6 col-md-2'
			//   },
			//   {
			//     id: 7, title: 'Электронный кошелек Юmoney',
			//     img: require('@/assets/img/umany.png'),
			//     col: 'col-6 col-md-2'
			//   },
			// ]
			card: [
				{
					img: 'Mastercard.svg',
					text: 'Карты платежной системы MasterCard'
				},
				{
					img: 'visa.svg',
					text: 'Карты платежной системы VISA '
				},
				{
					img: 'maestro.svg',
					text: 'Карты платежной системы Maestro'
				},
				{
					img: 'mir.svg',
					text: 'Карты платежной системы Мир'
				},
				{
					img: 'sbp.png',
					text: 'Система быстрых платежей'
				},
				{
					img: 'qiwi.svg',
					text: 'Электронный кошелек Qiwi'
				},
				{
					img: 'uman.svg',
					text: 'Электронный кошелек Юmoney'
				},
			]
		}
	}
}
</script>

<template>
	<div class="row mt-5 mb-5 gradient justify-content-between loan" id="blockThree">
		<div class="col-12 text-center">
			<h2 class="title">Способы получения займа</h2>
			<div class="loan__subtitle">Наш сервис — это возможность получить займ любым удобным способом!</div>
		</div>
		<div class="col-12">
			<div class="loan__list justify-content-between">
				<div class="loan__item" v-for="(item, i) in card" :key="i">
					
						<div class="loan__img">
							<img :src="require(`@/assets/img/${item.img}`)" alt="svg">
						</div>
						<div class="loan__text">{{ item.text }}</div>
					
				</div>
			</div>
		</div>
	</div>

</template>

<style scoped lang="scss">
.loan {
	border-radius: 30px;
	background: #FFF;
	box-shadow: 0px 4px 12px 0px rgba(35, 69, 129, 0.05);
	padding: 65px 30px;
	&__subtitle {
		font-size: 16px;
		font-weight: 400;
		margin-top: 15px;
		margin-bottom: 55px;
	}
	&__list {
		display: flex;
		text-align: center;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 -10px;
	}
	&__item {
		display: flex;
		flex-direction: column;
		flex: 0 0 calc(100% / 7);
		max-width: calc(100% / 7);
		padding: 0 10px;
		@media(max-width: 990px) {
			flex: 0 0 calc(100% / 2);
			max-width: calc(100% / 2);
			padding: 0 10px 20px;
		}
	}
	&__img {
		height: 90px;
		position: relative;
		border: solid 1px #F2F4F7;
		margin-bottom: 15px;
		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}
	&__text {
		color: #818181;
		text-align: center;
		font-size: 12px;
		height: 30px;
	}
}
/* .swiper-button-prev::after{
	cursor: pointer;
	content: "" !important;
	width: 35px;
	height: 35px;
	background: url(@/assets/img/prev.png);
	background-size: contain;
	display: block;
	margin-right: 15px;
	border-radius: 20px;
	border: 0;
}

.swiper-button-next::after {
	cursor: pointer;
	content: "" !important;
	width: 35px;
	height: 35px;
	background: url(@/assets/img/next.png);
	background-size: contain;
	display: block;
} */
</style>
