<script>
export default {
  name: 'BlackCard',
}
</script>

<template>
<div class="info text-center">
<slot></slot>
</div>
</template>

<style scoped>
.info{
  border-radius: 10px;
  background: #DFEDF5;
  color: #005BF7;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  padding: 5px 10px;
}
</style>