<script>
import BlueCard from "@/components/BlueCard.vue";
import RangeCard from "@/components/RangeCard.vue";
import LeadForm from "@/components/LeadForm.vue";


import InfoCard from "@/components/InfoCard.vue";


export default {
	name:'BlockEight',
	components: {LeadForm, RangeCard, BlueCard, InfoCard},

}
</script>

<template>
	<div class="position-relative mt-4 py-3 px-2 range-block-eight banner--white" id="leadForm2">
		<div class="card-range">
			<h2 class="title">Оформите заявку уже сейчас</h2>
			<div class="range-block-eight__wrap">
				
					<range-card></range-card>
				
			</div>
		</div>
	</div>
</template>

<style  lang="scss">
.range-block-eight {
	&.banner--white {
		// padding: 25px;
		// @media (max-width: 767px) {
		// 	padding: 5px;
		// }
	}
	&__wrap {
		.range-block {
			flex: 0 0 100%!important;
			max-width: 100%!important;
			@media (max-width: 767px) {
				margin-bottom: 20px;
			}
			&__item {		
				flex: 0 0 50%!important;
				max-width: 50%!important;
				@media (max-width: 767px) {
					flex: 0 0 100%!important;
					max-width: 100%!important;
				}
			}
			&__wrap {
				align-items: center;
				margin: 0;
			}
		}
		&__btn {
			color: #FFF;
			border: none;
			text-align: center;
			font-size: 20px;
			font-weight: 700;
			line-height: 45px;
			height: 50px;
			border-radius: 8px;
			background: linear-gradient(180deg, #2374FE 0%, #0852D1 100%);
			box-shadow: 0px 8px 18px 0px rgba(25, 74, 158, 0.29);
			cursor: pointer;
			display: block;
			max-width: 50%;
			margin: 0 auto;
			width: 100%;
			@media (max-width: 767px) {
				max-width: 100%;
				line-height: 100%;
				height: auto;
				padding: 15px 5px;
				width: 100%!important;
			}
		}
		.banner-btn {
			@media (max-width: 767px) {
				display: block;
				width: 100%!important;
			}
		}
	}

}

</style>