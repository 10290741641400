<script>

import {defineComponent} from "vue";
import RangeCard from "@/components/RangeCard.vue";
import InfoCard from "@/components/InfoCard.vue";
import LeadForm from "@/components/LeadForm.vue";
import BlueCard from "@/components/BlueCard.vue";

export default defineComponent({
	name: 'BlockOne',
	components: {
		BlueCard,
		LeadForm,
		InfoCard,
		RangeCard
	},
	methods: {
		changeTime() {
			let h_time = document.getElementsByClassName("header-time");

			for(let h of h_time){
				if (h != null) {
					let oldDate = new Date();
					let date = new Date(oldDate.getTime() + 7 * 60000);
					let mins = date.getMinutes();
					let hrs = date.getHours();
					if (mins <= 9) {
						mins = '0' + String(mins);
					}

					if (hrs <= 9) {
						hrs = '0' + String(hrs);
					}
					h.innerHTML = `${hrs}:${mins}`;
				}
			}

		},
		getId(prefix, id) {
			return prefix + '_' + id;
		},
	},

	data(){
		return{

		}
	},
	mounted() {
		this.changeTime()
	}
})
</script>

<template>
	<div class="row position-relative banner pt-4">
		<div class="col-12 col-xl-6 mb-4 mb-xl-0">
			<div class="banner__img banner--white">
				<img src="@/assets/img/girl.png" alt="girl">
			</div>
		</div>
		
		<div class="col-12 col-xl-6">
			<div class=" banner__info">
				<div class="row">
					<div class="col-12 banner--white mb-4 mb-xl-2">
						<div class="row banner__list ">
							<div class="col-12 col-xl-4 banner-info__item">
								<div class="row">
									<div class="col-12">
										<div class="row">
											<div class="col-12">
												<div class="d-flex align-items-cente banner-info__top">
													<img src="@/assets/img/num-red1.svg" alt="svg">
													<div>Заполните анкету</div>
												</div>
											</div>
											<div class="col-12 banner-info__body">Это займет у вас не более 10 минут</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-xl-4 banner-info__item">
								<div class="row">
									<div class="col-12">
										<div class="row">
											<div class="col-12">
												<div class="d-flex align-items-cente banner-info__top">
													<img src="@/assets/img/num-red2.svg" alt="svg">
													<div>Получите ответ</div>
												</div>
											</div>
											<div class="col-12 banner-info__body">Мы дадим ответ в течении 5 минут</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-xl-4 banner-info__item">
								<div class="row">
									<div class="col-12">
										<div class="row">
											<div class="col-12">
												<div class="d-flex align-items-cente banner-info__top">
													<img src="@/assets/img/num-red3.svg" alt="svg">
													<div>Завершите оформление</div>
												</div>
											</div>
											<div class="col-12 banner-info__body">Выберите условия и завершите оформление</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 banner--white ">
						<div class=" p-2 p-md-4">
							<range-card></range-card>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>

</template>

<style scoped lang="scss">
.banner-info {
	&__list {
		padding: 20px;
	}
	&__item {
		padding: 20px 25px;
		@media (max-width: 767px) {
			margin-bottom: 20px;
		}
	}
	&__info {}
	&__top {
		margin-bottom: 15px;
		height: 40px;
		line-height: 100%;

		img {
			margin-right: 10px;
		}
		div {
			color: #1E1E1E;
			font-size: 16px;
			font-weight: 700;
		}
	}
	&__body {
		color: #818181;
		font-size: 14px;
		font-weight: 400;
		line-height: 120%;
	}
}
.banner {
	&__img {
		height: 100%;
		img{
			height: 100%;
		}
	}

}
.banner-btn {
	color: #FFF;

	text-align: center;
	font-size: 20px;
	font-weight: 700;
	line-height: normal;
	border-radius: 8px;
	background: linear-gradient(180deg, #2374FE 0%, #0852D1 100%);

	box-shadow: 0px 8px 18px 0px rgba(25, 74, 158, 0.29);
	cursor: pointer;
	padding: 15px 0;
	margin-top: 20px;
}
</style>