<template>
	<div class="container">
		<div class="order__title title text-start my-4">Заполнение анкеты не обязывает получать займ — понравятся условия, МФО переведёт онлайн</div>
		<div class="order-block  order-block-two">
			<span class="order-load-strip"><span></span></span>
			<div class="order-block__top row justify-content-center  mb-3">
				<div class="order-block__title col-12 col-md-auto order-2 text-center order-md-2">Идет обработка заявки <img class="order-loader" src="@/assets/loader.gif" alt=""></div>
				<div class="col-auto col-md-auto order-1 text-end order-md-2 order-block__top-info">следующий шаг: <b>Решение</b></div>
			</div>
			<div class="order-block__body row">
				<div class="order-block-two__time  col-12">
					<div class="row justify-content-center text-center mb-3">
						<div class="col-12 order-block-two__time-text">Время обработки</div>
						<div class="col-12 order-block-two__time-time">{{ timerCount }} сек</div>
					</div>
				</div>
				<div class="order-block-two__name col-auto mx-auto text-center mb-3">{{ this.lead.person.f }} {{ this.lead.person.i }} {{ this.lead.person.o }}</div>
				<div class="order-block-two__info col-12">
					<div class="row justify-content-center">
						<div class="col-auto text-center">
							<span class="order-block-two__info-text">Сумма займа</span>
							<div class="order-block-two__info-sum">{{ this.lead.sum }}</div>
						</div>
						<div class="col-auto text-center">
							<span class="order-block-two__info-text">Срок займа</span>
							<div class="order-block-two__info-sum">{{ this.lead.term }}</div>
						</div>
					</div>
				</div>
				<div class="order-block-two__finish col-12 mt-3 text-center">Дождитесь решения системы - не закрывайте сайт. Решение вы увидите здесь</div>
			</div>
			<div class="order-block__body row">

			</div>
		</div>
		
	</div>
</template>

<script>
import LeadForm from "@/components/LeadForm.vue";
import RangeCard from "@/components/RangeCard.vue";
import router from '@/router';
export default {
	components: {
		LeadForm,
		RangeCard
	},
	data() {
		return {
			lead: this.lead,
			timerCount: 0
		}
	},
	methods: {
		countDownTimer () {
			if (this.timerCount < 8) {
				setTimeout(() => {
					this.timerCount++
					this.countDownTimer()
				}, 1000)
			} else {
				this.$router.push('/order-3')
			}
		}
	},
	created () {
		this.countDownTimer()
	}
}
</script>

<style lang="scss">
.order-loader {
	width: 35px;
}
.order-block-two {
	.order-block__top-info {
		position: absolute;
		top: 0;
		right: 20px;
		@media (max-width: 991px) {
			position: relative;
			top: auto;
			right: auto;
		}
	}
	&__time  {}
	&__time-text {}
		color: #818181;
		font-size: 14px;
		font-weight: 400;
	&__time-time {
		color: #FA155A;
		font-size: 20px;
		font-weight: 500;
	}
	&__name {
		color: #1E1E1E;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		border-radius: 8px;
		border: 1px solid #005BF7;
		background: #FFF;
		display: inline-block;
		padding: 9px  10px 11px;
	}
	&__info-text {
		color: #818181;
		font-size: 14px;
		font-weight: 400;
	}
	&__info-sum {
		color: #005BF7;
		font-size: 24px;
		font-weight: 500;
	}
	&__finish {
		color: #1E1E1E;
		font-size: 20px;
		font-weight: 400;
		line-height: 19.6px;
		max-width: 50%;
		margin: 0 auto;
		@media (max-width: 991px) {
			max-width: 100%;
		}
	}
	.order-load-strip {
	span {
		width: 66.66%;
	}
}
}

</style>