<script>

export default {
  name: 'ServiceBlock',
  components:{

  },

  setup(){

  },
  data(){
    return{

    }
  }
}
</script>

<template>
  <div class="container" id="footer">
    <footer>
      <div class="row pt-5 mt-5 bord">
        <div class="col-12 col-md-3">
          <p class="text-bold">Телефон горячей линии</p>
          <p class="text-grey">+7 (343) 300-92-39</p>
        </div>
        <div class="col-12 col-md-3">
          <p class="text-bold">Время работы</p>
          <p class="text-grey">10:00 - 19:00 МСК</p>
        </div>
        <div class="col-12 col-md-3">
          <p class="text-bold">E-mail</p>
          <p class="text-grey">work-zaim@yandex.ru</p>
        </div>
        <div class="col-12 col-md-3">
          <p class="text-bold">Почтовый адрес</p>
          <p class="text-grey">г. Екатеринбург ул.Шейкмана 9</p>
        </div>
      </div>
      <div class="row mt-3 align-items-center">
        <div class="col-auto">
          <img src="@/assets/img/18.png" alt="">
        </div>
        <div class="col-10 col-md-3 footer-smtext">
          Сервис предназначен для лиц старше 18 лет
        </div>
        <div class="col-12 col-md-5 mt-3 mt-md-0">
          <div class="row">
            <div class="col-auto footer-ico">
              <img src="@/assets/img/Mastercard.svg" alt="" class="img-fluid">
            </div>
            <div class="col-auto footer-ico">
              <img src="@/assets/img/visa.svg" alt="" class="img-fluid">
            </div>
            <div class="col-auto footer-ico">
              <img src="@/assets/img/maestro.svg" alt="" class="img-fluid">
            </div>
            <div class="col-auto footer-ico">
              <img src="@/assets/img/mir.svg" alt="" class="img-fluid">
            </div>
            <div class="col-auto footer-ico">
              <img src="@/assets/img/qiwi.svg" alt="" class="img-fluid">
            </div>
            <div class="col-auto footer-ico">
              <img src="@/assets/img/uman.svg" alt="" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
      <p class="text-grey mt-4">
        * У пользователей сервиса есть возможность получить займ с минимальной процентной ставкой одобренной МФО.
        Подробности при выборе персонального предложения.Займ у партнеров выдается в российских рублях гражданам Российской Федерации,
        на банковский счет, на карту или наличными. Минимальная сумма займа: 1 000 рублей. Максимальная сумма займа: 100 000 рублей.
        Процентная ставка и срок займа: по решению МФО.
      </p>
      <div class="row mt-5 mb-5">
        <div class="col-10 col-md-6">
          Перечень кредитных организаций осуществляющих предоставление займов:
        </div>
        <!-- <div class="col-2 col-md-1">
          <span class="row-down" @click="show=!show"><i class="bi bi-chevron-down"></i></span>
        </div> -->
        <div class="col-12 mt-4">
          <ul>
            <li class="text-grey mt-2">ООО МКК "ПЛИСКОВ", 410052, г. Саратов, просп. им. 50 Лет Октября, дом 132А, офис 33, Лицензия № 1903363009056, ИНН 6453158170, ОГРН 1186451026288, процентная ставка от 0,1 % в день</li>
            <li class="text-grey mt-2">ООО МКК "Кредиттер", 115280, г. Москва, ул. Ленинская слобода, д. 19, стр. 6, ком. 7, Лицензия № 1903045009373, ИНН 7702463482, ОГРН 1197746100530, процентная ставка от 0,99 % в день</li>
            <li class="text-grey mt-2">ООО "МКК КАНГАРИЯ", 344101, г. Ростов-на-Дону, ул. Ленинградская, д. 7, пом. № П-13, Лицензия № 1904067009295, ИНН 9201526872, ОГРН 1189204008564, процентная ставка от 0,99 % в день</li>
            <li class="text-grey mt-2">ООО МФК "ДЗП-Центр", 190031, г. Санкт-Петербург, пер. Спасский, д. 14/35, лит. А, пом. 38 Н, Лицензия № 651403140005467, ИНН 7838500558, ОГРН 1147847029990, процентная ставка от 0 % в день</li>
            <li class="text-grey mt-2">ООО МФК "ЦФП", 117218, г. Москва, ул. Кржижановского, д. 29, корп. 5, пом. I, ком. 10, Лицензия № 2110177000840, ИНН 7733783309, ОГРН 1117746890645, процентная ставка от 0 % в день</li>
          </ul>
        </div>
      </div>
    </footer>
    <div class="service mb-5" id="service">
      <div class="service__title title text-start mb-4">О сервисе</div>
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item me-3 mt-3 mt-md-0" role="presentation">
          <button class="nav-link active"
                  id="pills-service-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-service"
                  type="button"
                  role="tab"
                  aria-controls="pills-service"
                  aria-selected="true">О сервисе
          </button>
        </li>
        <li class="nav-item me-3 mt-3 mt-md-0" role="presentation">
          <button class="nav-link"
                  id="pills-safety-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-safety"
                  type="button"
                  role="tab"
                  aria-controls="pills-safety"
                  aria-selected="false">Безопасность
          </button>
        </li>
        <li class="nav-item me-3 mt-3 mt-md-0" role="presentation">
          <button class="nav-link"
                  id="pills-doc-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-doc"
                  type="button"
                  role="tab"
                  aria-controls="pills-doc"
                  aria-selected="false">Документы
          </button>
        </li>
        <li class="nav-item mt-3 mt-md-0" role="presentation">
          <button class="nav-link"
                  id="pills-partnership-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-partnership"
                  type="button"
                  role="tab"
                  aria-controls="pills-partnership"
                  aria-selected="false">Сотрудничество
          </button>
        </li>
      </ul>
      <div class="tab-content  pb-5" id="pills-tabContent">
        <div class="tab-pane fade show active"
            id="pills-service"
            role="tabpanel"
            aria-labelledby="pills-service-tab"
            tabindex="0">
          <h5 class="mt-4 mb-4">О сервисе</h5>
          <p>Сервис оказывает абсолютно бесплатные услуги по подбору кредитных продуктов для клиентов, а именно предлагает клиенту список предложений кредитных
            учреждений, и прочих финансовых организаций, в которые клиент может обратиться с целью оформления заявки на кредитный продукт.
          </p>
          <p>
            Сервис не является банком или кредитором, не относится к финансовым учреждениям и не несёт ответственности за последствия любых заключенных договоров
            кредитования или условия по ним.
          </p>
          <p>
            Персональные данные защищены. Вся указанная информация находится в безопасности. Мы не передаём персональные данные третьим лицам. Никто кроме вас
            не имеет доступ к информации которую вы заполнили на сайте.
          </p>
          <p>
            Услуги на проекте оказываются ИП Горшенина А.А. ОГРНИП 320665800078661, ИНН 662341773168, Оператор персональных данных 66-20-006644 от 21.08.2021г.
            Фактический адрес: 620014, Свердловская область, город Екатеринбург, улица Шейнкмана , дом 9.
          </p>
        </div>
        <div class="tab-pane fade"
            id="pills-safety"
            role="tabpanel"
            aria-labelledby="pills-safety-tab"
            tabindex="0">
          <h5 class="text-bold mt-5">Оператор персональных данных</h5>
          <p>Регистрация в РКН №72-21-005913 Приказ № 145 от 13.08.2021</p>

          <h5 class="text-bold">SSL сертификат</h5>
          <p>Сертификат обеспечивает защиту ваших данных, безопасность платежей и предотвращает несанкционированный доступ к информации</p>

          <h5 class="text-bold">Все прозрачно</h5>
          <p>Мы ведем прозрачную политику, вся информация о стоимости и условиях открыто представлена на сайте</p>

          <h5 class="text-bold">Ваши данные защищены</h5>
          <p>Ваши персональные данные и данные вашей банковской карты никогда не перейдут третьим лицам</p>

          <h5 class="text-bold">Не перезваниваем и не требуем фото с паспортом</h5>
          <p>Достаточно анкеты</p>

          <h5 class="text-bold">Не беспокоим ваших родных и коллег</h5>
          <p>Мы доверяем вам и не просим справки с работы, не звоним родственникам и коллегам</p>
        </div>
        <div class="tab-pane fade"
            id="pills-doc"
            role="tabpanel"
            aria-labelledby="pills-doc-tab"
            tabindex="0">
          <p class="text-bold mt-5">Заполняя анкету на нашем сервисе Вы соглашаетесь с условиями и соглашениями указанными на сайте</p>
          <ul>
            <li><a target="_blank" href="./politic-obrab-dan.pdf">Политика обработки персональных данных</a></li>
            <li><a target="_blank" href="./pologenie_ob_obrab.pdf">Положение об&nbsp;обработке персональных данных</a></li>
            <li><a target="_blank" href="./sogl_obrab.pdf">Согласие на обработку персональных данных</a></li>
            <li><a target="_blank" href="./sogl_phone_email.pdf">Согласие на звонки, E-mail рассылки с&nbsp;доменов партнеров сервиса</a></li>
          </ul>
        </div>
        <div class="tab-pane fade"
            id="pills-partnership"
            role="tabpanel"
            aria-labelledby="pills-partnership-tab"
            tabindex="0">
          <div class="row mt-5">
            <div class="col-auto">
              <a href="tg://resolve?domain=nik_gor86" class="cursor"><img src="@/assets/img/telegram.png" alt="" class="img-fluid"></a>
            </div>
            <div class="col-10">
              <a href="tg://resolve?domain=nik_gor86" class="cursor">Сотрудничество с нами</a>
            </div>
          </div>
          <p class="mt-3">Для представителей МФО, кредитных брокеров и вебмастеров</p>
          <p class="smtext">E-mail: <a href="mailto:work-zaim@yandex.ru">work-zaim@yandex.ru</a></p>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.service {
  border-radius: 33px;
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(35, 69, 129, 0.05);
  padding: 20px 35px;
}
.nav-pills .nav-link.active{
  border-radius: 8px;
  border: 1px solid #787878;
  background: #005BF7;
}

.nav-link{
  border-radius: 8px;
  border: 1px solid #005BF7;
  background: #fff;
  color: #005BF7;
}

a{
  text-decoration: none;
  color: #212529;
}

a:hover{
  border-bottom: solid 1px;
}

.smtext{
  font-size: 14px;
}
.tab-pane {
  p {
    color: #212529;
  }
}
.bord{
	border-top: 1px solid #E3E3E3;
}

.text-grey{
	color: #818181;
}

.footer-smtext{
	font-size: 14px;
}

.row-down{
	border-radius: 12px;
	border: 1px solid rgba(80, 80, 80, 0.30);
	background: #FED305;
	color: #000;
	padding: 5px 15px;
	align-items: center;
}
.footer-ico {
	background-color: #fff;
	margin-right: 10px;
	position: relative;
	height: 30px;
	line-height: 30px;
	text-align: center;
	img {
		max-width: 15px;
	}
}
</style>