<template>
	<div class="container">
		<div class="order__title title text-start my-4">Заполнение анкеты не обязывает получать займ — понравятся условия, МФО переведёт онлайн</div>
		<div class="order-block  order-block-three">
			<span class="order-load-strip"><span></span></span>
			<div class="order-block__top row  justify-content-center  mb-3">
				<div class="order-block__title col-12 col-md-auto order-2 text-center order-md-2">Обработка вашей заявки завершена</div>
				<div class="col-auto col-md-auto order-1 text-end order-md-2 order-block__top-info">
					<div class="row align-items-center order-block-three__info">
						<div class="col-auto pe-0"><img src="@/assets/img/fire-white.svg" alt="svg"></div>
						<div class="col-auto ">Выдача</div>
					</div>
				</div>
			</div>
			<div class="order-block__body row">
				<div class="order-block-two__time  col-12">
					<div class="row justify-content-center text-center mb-3">
						<div class="col-12 order-block-two__time-text">Время обработки</div>
						<div class="col-12 order-block-two__time-time">8 сек</div>
					</div>
				</div>
				<div class="order-block-three__name col-auto mx-auto text-center mb-2">{{ this.lead.person.f }} {{ this.lead.person.i }} {{ this.lead.person.o }}, поздравляем&nbsp;Вас, ваша заявка <span>одобрена!</span></div>
				<div class="order-block-two__info col-12">
					<div class="row justify-content-center">
						<div class="col-auto text-center">
							<span class="order-block-two__info-text">Сумма займа</span>
							<div class="order-block-two__info-sum">{{ this.lead.sum}}</div>
						</div>
						<div class="col-auto text-center">
							<span class="order-block-two__info-text">Срок займа</span>
							<div class="order-block-two__info-sum">{{ this.lead.term }}</div>
						</div>
					</div>
				</div>
				<div class=" col-12 mt-3 text-center order-block-three__btn">
					<a href="/vitrina1/" target="_blank" class="text-center">Выбрать предложение</a>
				</div>
			</div>

		</div>
		
	</div>
</template>

<script>
import LeadForm from "@/components/LeadForm.vue";
import RangeCard from "@/components/RangeCard.vue";
export default {
	components: {
		LeadForm,
		RangeCard
	},
	data() {
		return {
			lead: this.lead,
		}
	},
}
</script>

<style lang="scss">
.order-loader {
	width: 35px;
}
.order-block-three {
	&__name {
		color: #1E1E1E;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		border-radius: 8px;
		background: #FFF;
		display: inline-block;
		padding: 9px  10px 11px;
		max-width: 50%;
		margin: 0 auto;
		@media (max-width: 767px) {
			max-width: 100%;
		}
		span {
			color: #005BF7;
		}
	}
	&__btn {
		a {
			color: #FFF;
			font-size: 20px;
			display: inline-block;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			border: none;
			border-radius: 8px;
			background: linear-gradient(180deg, #2374FE 0%, #0852D1 100%);
			box-shadow: 0px 8px 18px 0px rgba(25, 74, 158, 0.29);
			padding: 15px 40px;
			text-decoration: none;

			@media (max-width: 575px) {
				font-size: 16px;
			}
		}
	}
	&__info {
		img {
			margin-bottom: 3px;
		}
	}
	.order-block__top-info {
		position: absolute;
		right: 0;
		top: 2px;
		@media(max-width: 767px) {
			position: relative;
			right: auto;
			top: auto;
		}
	}
	.order-load-strip {
	span {
		width: 100%;
	}
}
}

</style>