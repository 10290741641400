import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

let app = createApp(App);

app.config.globalProperties.lead = {
    sum: 50000,
    term: 23,
    person:{
        f:'',
        i:'',
        o:'',
        tel: '',
        dr:''
    }
};

app.use(store).use(router).mount('#app')
