<script >
export default {
  name: 'YellowAncorLink',
  props:{
    ancor: {
      type: String
    }
  }
}
</script>

<template>
  <div>
    <a class="btn blue-btn cursor" :href="ancor">
      <slot></slot>
    </a>
  </div>
</template>

<style scoped lang="scss">

.blue-btn{
  /* border-radius: 12px;
  border: 1px solid rgba(80, 80, 80, 0.30);
  background: #FED305; */

  border-radius: 13px;
  background: linear-gradient(180deg, #2374FE 0%, #0852D1 100%);
  box-shadow: 0px 8px 18px rgba(25, 74, 158, 0.3);
  color: #FFF;
  font-size: 20px;
  font-weight: 700;
  height: 45px;
  text-align: center;
  transition: 0.3s;
  //width: 320px;
  width: 100%;
  max-width: 320px;
  &:active {
    color: #fff;
  }
  &:hover {
    box-shadow: 0px 2px 10px rgba(25, 74, 158, 0.3);
    text-shadow: 1px 1px 1px #000;
  }
}
</style>