<script>
import CardColumn from "@/components/CardColumn.vue";
import CardColorBg from "@/components/CardColorBg.vue";
import BtnYellow from "@/components/BtnYellow.vue";
import YellowAncorLink from "@/components/YellowAncorLink.vue";

export default {
	name: 'BlockSix',
	components: {YellowAncorLink, BtnYellow, CardColorBg, CardColumn},
	data(){
		return{
			cards:[
				{
					img: 'service1.svg',
					title: 'Сервис работает мгновенно',
					text: 'Автоматически и без участия человека'
				},
				{
					img: 'service2.svg',
					title: 'Мы не стараемся быть банком',
					text: 'Лояльно относимся к кредитной истории или ее отсутствию'
				},
				{
					img: 'service3.svg',
					title: 'Деньги 24/7',
					text: 'Сервису не нужен отдых! Он обрабатывает займы круглосуточно и без выходных'
				},
				{
					img: 'service4.svg',
					title: 'Приготовьте только паспорт',
					text: 'Сделку проходит дистанционно, ваше личное присутствие не нужно'
				},
			]
		}
	}
}
</script>

<template>
	<div class="my-5">
		<div class="row my-5" id="blockSix">
			<div class="col-12">
				<h2 class="title">Лучший сервис получить деньги быстро</h2>
			</div>
		</div>

		<div class="card-block">
			<div class="card-block__lis row">
				<div class="col-12 col-lg-6 col-xxl-3 card-block__item mb-3" v-for="(item, i) in cards" :key="i">
					<div class="card-block__top">
						<div class="card-block__img">
							<img :src="require(`@/assets/img/${item.img}`)" alt="svg" loading="lazy">
						</div>
						<div class="card-block__name">{{ item.title }}</div>
					</div>
					<div class="card-block__bott">
						<div class="card-block__text">{{ item.text }}</div>
					</div>
				</div>
				<div class="col-12 col-lg-4 btn-order mx-auto mt-2">
					<yellow-ancor-link :ancor="'#leadForm'">Оформить заявку</yellow-ancor-link>
				</div>

			</div>
		</div>
	</div>


</template>

<style scoped lang="scss">
.card-block {
	@media(max-width: 990px) {
		max-width: 80%;
		margin: 0 auto;
	}
	&__top {
		display: flex;
		align-items: center;
		border-radius: 16px;
		background: #FFF;
		box-shadow: 0px 4px 12px 0px rgba(35, 69, 129, 0.05);
		padding: 15px 10px;
	}
	&__bott{
		padding: 10px;
	}
	&__img{
		flex: 0 0 50px;
		max-width: 50px;
		height: 50px;
		border-radius: 12px;
		background: #1866EB;
		box-shadow: 0px 4px 12px 0px rgba(35, 69, 129, 0.05);
		position: relative;
		margin-right: 10px;
		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}
	&__name{
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
		@media(max-width: 990px) {
			font-size: 20px;
		}
	}
	&__text{
		color: #818181;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}
</style>