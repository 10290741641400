<script>
import {defineComponent} from "vue";
import BtnYellow from "@/components/BtnYellow.vue";
import BlackCard from "@/components/BlackCard.vue";
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css';

export default ({
	name: 'RangeCard',
	components: {BlackCard, BtnYellow, VueDatePicker },
	methods: {
		changeRange(id) {	
			let range = document.getElementsByClassName('range_' + id)
			let pr = document.getElementsByClassName('progress_' + id);
			for(let i = 0; i < range.length;i++ ){
				let fullWidth = parseInt(range[i].offsetWidth) - 15;
				let steps = range[i].getAttribute('max') - range[i].getAttribute('min');
				let oneWidth = fullWidth / steps;
				let width = oneWidth * (range[i].value - range[i].getAttribute('min'));
				width += 'px';
				pr[i].style.width = width;
			}			
			this.setupDate(this.lead.term);
			this.totalMoney();
		},
		setupDate(term) {		
			let finalDate = new Date()
			let currentYear = finalDate.getFullYear()
			finalDate.setDate(finalDate.getDate() + parseInt(term))
			const monthsRu = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октабря', 'ноября', 'декабря']
			let dW = finalDate.toLocaleString('ru-RU',  { weekday: 'long' })
			let dM = monthsRu[finalDate.getMonth()]
			let dY = currentYear !== finalDate.getFullYear() ? finalDate.getFullYear() : ''
			let dT = finalDate.getDate()+' '+dM+' '+dY
			this.finalDayOfWeek = dW	
			this.finalDateText = dT
		},
		getId(prefix, id) {
			return prefix + '_' + id;			
		},
		getClass(prefix, id) {
			return prefix + '_' + id;
		},
		totalMoney() {
			let percent = this.percentZaim
			let leadSum = parseInt(this.lead.sum)
			let leadDay = parseInt(this.lead.term)
			this.percentTotal = ((leadSum / 100 * percent ) * leadDay) + leadSum
		}
	},
	mounted() {
		this.setupDate(this.lead.term);
		this.changeRange('sum');
		this.changeRange('term')
	},
	data(){
		return{
			lead: this.lead,
			finalDayOfWeek: '',		
			finalDateText: '',
			percentTotal: 0,
			percentZaim: 3 //---- тестовый процент
		}
	},
	computed: {
		displayNone() {
			if (this.$route.path === '/order-1') {
				return true
			}
		}
	}

});
</script>

<template>
	<div class="row range-block__wrap" id="leadForm">
		<div class="col-12 range-block__item">
			<div class="row">
				<div class="col-12 col-xl-6 range-block card-text mt-3">
			<div class="row justify-content-between align-items-center">
				<div class="col-4 col-md-auto range__title">
					ЗАНЯТЬ
				</div>
				<div class="col-auto col-md-auto">
					<black-card class="w-100"><span class="sum" >{{ this.lead.sum}} ₽</span></black-card>
				</div>
			</div>
			<div class="p-2 mt-0 mt-lg-2 position-relative">
				<input :id="getId('range', 'sum')"
							 type="range"
							 min="1000"
							 max="100000"
							 step="1000"
							 :class="'slider form-range w-100 ' + getClass('range', 'sum')" data-block=".sum"
							 v-model="this.lead.sum"
							 @click="changeRange('sum')"
							 @input="changeRange('sum')"
							 @change="changeRange('sum');">
				<div :id="getId('progress', 'sum')" :class="'progress ' + getClass('progress', 'sum')"></div>
			</div>
			<div class="row text-rang">
				<div class="col-4">
					1 000 ₽
				</div>
				<div class="col-4 text-center">
					50 000 ₽
				</div>
				<div class="col-4 text-end">
					100 000 ₽
				</div>
			</div>
		</div>
		<div class="col-12 col-xl-6 range-block card-text mt-3">
			<div class="row justify-content-between align-items-center">
				<div class="col-4 col-md-auto range__title">
					НА СРОК
				</div>
				<div class="col-auto col-md-auto">
					<black-card class="w-100"><span class="term">{{ this.lead.term}} дней</span></black-card>
				</div>
			</div>
			<div class="p-2 mt-0 mt-lg-2 position-relative">
				<input :id="getId('range', 'term')"
							 type="range"
							 min="7"
							 max="40"
							 step="1"
							 v-model="this.lead.term"
							 :class="'slider form-range w-100 ' + getClass('range', 'term')"
							 data-block=".term"
							 @click="changeRange('term')"
							 @input="changeRange('term')"
							 @change="changeRange('term');">
				<div :id="getId('progress', 'term')" :class="'progress ' + getClass('progress', 'term')"></div>
			</div>
			<div class="row text-rang">
				<div class="col-4">
					7 дней
				</div>
				<div class="col-4 text-center">
					20 дней
				</div>
				<div class="col-4 text-end">
					40 дней
				</div>
			</div>
		</div>
			</div>
		</div>
		<div class="col-12 range-block__item">
			<div class="row my-3 text-center">
				<div class="col-12 range__calendar">
				<img src="@/assets/img/calendar.svg" alt="svg">
				<p>Дата возврата: {{ finalDayOfWeek }}, <span><strong> {{ finalDateText }}</strong></span></p>
			</div>
		</div>
		<div class="row card-text  pb-0 range__info justify-content-between">
		<div class="col-4 text-center ">
			<p class="mb-1 range__info-text">Сумма займа</p>
			<p class="mb-1 range__info-num" >{{this.lead.sum}} ₽</p>
		</div>
		<div class="col-4 text-center ">
			<p class="mb-1 range__info-text">Первый займ</p>
			<p class="mb-1 range__info-num--red">0%</p>
		</div>
		<div class="col-4 text-center ">
			<p class="mb-1 range__info-text">Верну</p>
			<p class="mb-1 range__info-num">{{this.lead.sum}} ₽</p>
		</div>
	</div>
	<div @click="$router.push('/order-1')"
		:class="['my-2 my-lg-4 w-75 text-center border mx-auto banner-btn', {'d-none' : displayNone}]"
	>
		Получить деньги уже в <span class="text-bold header-time" id="header-time">15:10</span>
	</div>
	</div>
	</div>

</template>

<style scoped lang="scss">

.banner-btn {
	color: #FFF;
	text-align: center;
	font-size: 20px;
	font-weight: 700;
	line-height: normal;
	border-radius: 8px;
	background: linear-gradient(180deg, #2374FE 0%, #0852D1 100%);

	box-shadow: 0px 8px 18px 0px rgba(25, 74, 158, 0.29);
	cursor: pointer;
	padding: 15px 0;
	margin-top: 20px;
	@media(max-width: 767px) {
		font-size: 18px;
		display: block;
		width: 100%!important;
	}
}
.white-bord-card {
	border-radius: 13px;
	border: 1px solid rgba(255, 255, 255, 0.50);
	background: transparent;
}

.card-title {
	font-size: 32px;
	font-weight: 700;
}

.card-title2 {
	font-size: 20px;
}

.text-rang {
	color: #787878;
	font-size: 14px;
}

.card-text {
	font-size: 16px;
}

.form-range {
	width: 100%;
	height: 1.5rem;
	padding: 0;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.form-range + .progress {
	background-color: #FA155A;
	height: 2px;
	width: calc(50% - 2px);
	position: absolute;
	top: 19px;
}

.progress {
	display: flex;
	height: 5px;
	overflow: hidden;
	font-size: 25px;
	background-color: #fff;
	border-radius: 16px;
}

input[type=range] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: transparent;
}

input[type=range]::-webkit-slider-runnable-track {
	border-radius: 50px;
	height: 2px;
	border: none;
	background-color: #818181;
}

input[type=range]::-webkit-slider-thumb {
	background: #fff;
	border: 1px solid #FA155A;
	border-radius: 50px;
	cursor: pointer;
	width: 19px;
	height: 19px;
	-webkit-appearance: none;
	margin-top: -8px;
	z-index: 5;
	position: relative;
}

input[type=range]::-moz-range-track {
	border-radius: 20px;
	height: 5px;
	border: none;
	background-color: #fff;
}

input[type=range]::-moz-range-thumb {
	background: #FED305;
	border: 5px solid #fff;
	border-radius: 50px;
	cursor: pointer;
}
.range {
	&__title {
		color: #646464;
		font-size: 20px;
		font-weight: 500;
		line-height: normal;
		@media(max-width: 767px) {
			font-size: 16px;
		}
	}
	&__info-text {
		color: #818181;
		font-size: 14px;
		font-weight: 400;
		line-height: normal;
		@media(max-width: 767px) {
			font-size: 12px;
		}
	}
	&__info-num {
		color: #005BF7;
		font-size: 24px;
		font-weight: 500;
		line-height: normal;
		@media(max-width: 767px) {
			font-size: 20px;
		}
		@media(max-width: 370px) {
			font-size: 16px;
		}
		&--red {
			color: red;
			font-size: 24px;
			font-weight: 500;
			line-height: normal
		}
	}
	&__calendar {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		@media(max-width: 767px) {
			font-size: 14px;
		}
		p {
			padding: 0 0 0 10px;
			margin: 0;
		}
	}
}
</style>