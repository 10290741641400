<script>
import BtnYellow from "@/components/BtnYellow.vue";

export default {
	name: 'FooterBlock',
	components: {BtnYellow},
	methods:{

	},
	data(){
		return{
			show: false,
		}
	}
}
</script>



<style scoped lang="scss">

</style>