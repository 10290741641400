<script >
export default {
  name: 'BtnYellow',
}
</script>

<template>
  <div>
      <button class="btn yellow-btn w-100 cursor">
        <slot></slot>
      </button>
  </div>
</template>

<style scoped>

.yellow-btn{
  border-radius: 12px;
  /* border: 1px solid rgba(80, 80, 80, 0.30); */
  background: #005BF7;
  color: #fff;
}
</style>