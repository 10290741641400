<script>
import {defineComponent} from "vue";
import BtnYellow from "@/components/BtnYellow.vue";
import RangeCard from "@/components/RangeCard.vue";
import BlueCard from "@/components/BlueCard.vue";
import InputMask from 'primevue/inputmask';
import ModalCard from "@/components/ModalCard.vue";

export default defineComponent({
	name: 'LeadForm',
	components: {
		ModalCard,
		BlueCard,
		RangeCard,
		BtnYellow,
		InputMask,
	},
	methods: {
		cleanText(val) {
			if (val.match(/[^А-ЯЙа-яй\s]/g)) {
				return val.replace(/[^А-ЯЙа-яй\s]/g, '');
			}
			return val;
		},

		cleanNumber(val) {
			if (val.match(/[^0-9\s]/g)) {
				return val.replace(/[^0-9\s]/g, '');
			}
			return val;
		},

		removeInvalid(){
			this.classList.remove('invalid');
		},
		saveStorage() {
			localStorage.setItem('f', this.lead.person.f);
			localStorage.setItem('i', this.lead.person.i);
			localStorage.setItem('o', this.lead.person.o);
			localStorage.setItem('dr', this.lead.person.dr);
			localStorage.setItem('tel', this.lead.person.tel);
		},
		validateForm() {
			let result = true;
			let inputf = document.getElementById('f');
			let inputi = document.getElementById('i');
			let inputo = document.getElementById('o');
			let inputdr = document.getElementById('dr');
			let inputtel = document.getElementById('tel'); 
			let dr = this.lead.person.dr
			let parts = dr.split('.');
			let day = parseInt(parts[0]);
			let month = parseInt(parts[1]);
			let year = parseInt(parts[2]);
			let currentDate = new Date();
			let currentYear = currentDate.getFullYear();
			let currentMonth = currentDate.getMonth() + 1; // +1, так как месяцы в JavaScript нумеруются с 0
			let currentDay = currentDate.getDate();

			let age = currentYear - year;

			if (this.lead.person.f === "") {
				inputf.classList.add('invalid');
				result = false;
			}else{
				inputf.classList.remove('invalid');
			}
			if (this.lead.person.i === "") {
				inputi.classList.add('invalid');
				result = false;
			}else{
				inputi.classList.remove('invalid');
			}
			if (this.lead.person.o === "") {
				inputo.classList.add('invalid');
				result = false;
			}else{
				inputo.classList.remove('invalid');
			}

			if (this.lead.person.dr === "" || 
					(month === 1 && day > 31) ||
					(month === 2 && day > 29) ||
					(month === 3 && day > 31) ||
					(month === 4 && day > 30) ||
					(month === 5 && day > 31) ||
					(month === 6 && day > 30) ||
					(month === 7 && day > 31) ||
					(month === 8 && day > 31) ||
					(month === 9 && day > 30) ||
					(month === 10 && day > 31) ||
					(month === 11 && day > 30) ||
					(month === 12 && day > 31) ||
					(month > 12) ||
					(year < (currentYear - 60)) ||
					(year > (currentYear - 18)) ||
					(age === 18 && month > currentMonth) ||
					(age === 18 && month === currentMonth && day > currentDay)) {
				inputdr.classList.add('invalid');
				result = false;
			}else{
				inputdr.classList.remove('invalid');
			}

			if (this.lead.person.tel === "") {
				inputtel.classList.add('invalid');
				result = false;
			}else{
				inputtel.classList.remove('invalid');
			}
			this.saveStorage();
			if (result) {
				this.validateStep1 = true;
				this.lead.validateStep1 = true;
				this.$router.push('/order-2')
			}

			return this.validateStep1;
		},

		modalShow() {
			console.log('modalShow');
			this.show = true;
		},

		hideDialog() {
			this.show = false;
		}
	},
	data() {
		return {
			lead: this.lead,
			validateStep1: false,
			show: false,
		}
	},
});
</script>

<template>
	<div>
		<div class="row mt-4 mt-md-0">
			<div class="col-12 col-md-6">
				<input id="f" type="text" class="lead-input p-3 form-control w-100" v-model="this.lead.person.f"
							 @keyup="lead.person.f = cleanText(lead.person.f); removeInvalid" placeholder="Фамилия">
			</div>
			<div class="col-12 col-md-6 mt-3 mt-md-0">
				<input id="i" type="text" class="lead-input p-3 form-control w-100" v-model="this.lead.person.i"
							 @keyup="lead.person.i = cleanText(lead.person.i)" placeholder="Имя">
			</div>
		</div>
		<div class="row mt-md-3">
			<div class="col-12 col-md-6 mt-3 mt-md-0">
				<input id="o" type="text" class="lead-input p-3 form-control w-100" v-model="this.lead.person.o"
							 @keyup="lead.person.o = cleanText(lead.person.o)" placeholder="Отчество">
			</div>
			<div class="col-12 col-md-6 mt-3 mt-md-0">
				<input-mask
					id="dr" type="text" 
					class="lead-input p-3 form-control w-100" 
					mask="99.99.9999" 
					:autoClear="false"
					v-model="this.lead.person.dr" placeholder="Дата рождения"></input-mask>
			</div>
		</div>
		<div class="row mt-md-3">
			<div class="col-12 col-md-6 mt-3 mt-md-0">
				<span class="d-none d-md-block"> Мобильный телефон</span>
				<div class="d-block d-md-none">
					<span class="yellow-text">+7%</span> к одобрению за заполнение поля <span
						class="text-bold">Мобильный телефон</span>
				</div>
				<input-mask id="tel" type="text" class="lead-input p-3 form-control w-100" mask="+7(999)999-99-99" :autoClear="false"
					v-model="this.lead.person.tel" placeholder="+7(___)___-__-__"></input-mask>
			</div>
			<div class="col-12 col-md-6 mt-3 mt-md-0 d-flex align-items-center flex-wrap">
				<div class="d-none d-md-block">
					<span class="yellow-text">+7%</span> к одобрению за заполнение поля <span
						class="text-bold">Мобильный телефон</span>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 mt-3">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
					<label class="form-check-label cursor link-modal" for="flexCheckChecked" @click="this.modalShow">
						Я ознакомлен и согласен с&nbsp;условиями документов
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-8 mt-3">
				<div class="row card-text align-items-center justify-content-end d-flex d-md-none">
					<div class="col-6">
						<p class="mb-1">Сумма займа</p>
						<p class="mb-1 text-bold">{{ this.lead.sum }} ₽</p>
					</div>
					<div class="col-6">
						<p class="mb-1 text-center">Первый займ</p>
						<p class="mb-1 yellow-text text-bold text-center">0%</p>
					</div>
				</div>
			</div>
			<div class="col-4 col-md-3 mt-3">
				<btn-yellow @click="validateForm">Далее</btn-yellow>
			</div>
		</div>
	</div>


	<modal-card :show="this.show">
		<div>
			<div class="row">
				<div class="col-12 text-end">
					<i class="bi bi-x-lg" @click="hideDialog"></i>
				</div>
			</div>
			§ Заполняя заявку на сайте вы даете согласие со&nbsp;следующими условиями:
			<a href="./politic-obrab-dan.pdf" target="_blank" style="color: #fff;" class="lgrey-bot cursor">
				Политики обработки персональных данных</a>,
			<a href="./pologenie_ob_obrab.pdf" target="_blank" style="color: #fff;" class="lgrey-bot cursor">Положение об обработке
				персональных данных</a>,
			<a href="./sogl_obrab.pdf" target="_blank" style="color: #fff;" class="lgrey-bot cursor">Согласие на
				обработку персональных данных</a>.
			<a href="./sogl_phone_email.pdf" style="color: #fff;" target="_blank"
				 class="lgrey-bot cursor">Согласие на звонки, E-mail рассылки с доменов партнеров сервиса</a>.
		</div>
	</modal-card>
</template>

<style scoped lang="scss">
.lead-input {
	border-radius: 10px;
	border: 1px solid #E3E3E3;
	background: transparent;
	color: #818181;
}

.lead-input::placeholder {
	color: #818181;
}

.form-check-input:checked {
	background-color: #005BF7;
	border: 1px solid #005BF7;
}

.link-modal:hover{
	text-decoration: underline;
}

.form-check-input[data-v-80bbdb20]:checked {
	background-color: #005BF7;
	border: 1px solid #005BF7;
}

.form-check-input:checked[type=checkbox] {
	--bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 20 20' %3e%3cpath fill= 'none' stroke= '%999999' stroke-linecap= 'round' stroke-linejoin= 'round' stroke-width= '3' d= 'm6 10 3 3 6-6' /%3e%3c/svg%3e);
}

.invalid {
	border: 1px solid red;
}

@media (max-width: 767px) {
	.card-text {
		font-size: 14px;
		color: #C4C4C4;
	}
}
</style>