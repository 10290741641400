<template>
	<div class="container">
		<div class="order__title title text-start my-4">Заполнение анкеты не обязывает получать займ — понравятся условия, МФО переведёт онлайн</div>
		<div class="order-block">
			<span class="order-load-strip"><span></span></span>
			<div class="order-block__top row justify-content-md-between justify-content-center mb-3">
				<div class="order-block__title col-12 col-md-auto order-2 text-center text-md-left order-md-2">Получите +10% к одобрению, заполнив первый шаг</div>
				<div class="col-auto order-1 order-md-2 order-block__top-info">Шаг 1 из 3</div>
			</div>
			<div class="order-block__body row">
				<div class="col-12 col-md-6"><range-card></range-card></div>
				<div class="col-12 col-md-6"><lead-form></lead-form></div>
			</div>
		</div>
		
	</div>
</template>

<script>
import LeadForm from "@/components/LeadForm.vue";
import RangeCard from "@/components/RangeCard.vue";
export default {
	components: {
		LeadForm,
		RangeCard
	},
}
</script>

<style lang="scss">
.order-block {
	background-color: #fff;
	border-radius: 30px;
	padding: 30px;
	position: relative;
	overflow: hidden;
	margin-top: 50px;
	&__top {
		position: relative;
	}
	&__title {
		color: #313131;
		font-size: 24px;
		font-weight: 500;
		line-height: 110%;
		margin-bottom: 10px;
		@media(max-width:767px) {
			font-size: 20px;
		}
	}
	&__top-info {
		color: #FFF;
		text-align: center;
		font-size: 14px;
		font-weight: 500;
		border-radius: 38px;
		background: #005BF7;
		display: inline-block;
		height: 25px;
		line-height: 25px;
		margin-bottom: 10px;
	}

	&__body {
		.range-block {
			flex: 0 0 100%;
			width: 100%;
		}
		.yellow-text {
			color: #005BF7;
			font-weight: 700;
		}

	}
	.order-load-strip {
	display: block;
	width: 100%;
	position: absolute;
	height: 15px;
	background-color: #E3E3E3;
	top: 0;
	left: 0;
	right: 0;
	span {
		display: block;
		width: 33.33%;
		height: 100%;
		background-color: #FA155A;
	}
}
}

</style>